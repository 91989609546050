import { graphql } from "gatsby"
import React, { useState } from "react"
import { Appear, BlogItems, Filters, LetsTalk, LogoTransitionType, SEO } from "../components"
import { useLayout } from "../context"
import { BlogItem } from "../models/BlogItem"
import { BlogLink } from "../models/BlogLink"
import { BlogPost } from "../models/BlogPost"
import { BlogCategory } from "../models/BlogCategory"

interface BlogPage {
  id: string
  seoMetaTags: object
  title: string
}

interface Props {
  data: {
    page: BlogPage
    categories: {
      nodes: Array<BlogCategory>
    }
    posts: {
      edges: Array<{
        node: BlogPost
      }>
      totalCount: number
    }
    links: {
      edges: Array<{
        node: BlogLink
      }>
      totalCount: number
    }
  }
}

const Insights = (props: Props) => {
  const { seoMetaTags, title } = props.data.page
  const blogPosts = props.data.posts.edges.map((edge) => edge.node)
  const blogLinks = props.data.links.edges.map((edge) => edge.node)

  const blogItems: BlogItem[] = [...blogPosts, ...blogLinks]
    .filter((item) => new Date(item.publicationDate) <= new Date())
    .sort((a, b) => +new Date(b.publicationDate) - +new Date(a.publicationDate))

  const { startLogoTransition } = useLayout()

  // Items filter
  const filters = props.data.categories.nodes.map((node) => node.name)
  filters.unshift("All")

  const [selectedFilter, setSelectedFilter] = useState(filters[0])

  const selectFilter = (filter: string) => {
    setSelectedFilter(filter)
    startLogoTransition(LogoTransitionType.JumpAndSpin)
  }

  return (
    <>
      <SEO seoMetaTags={seoMetaTags} />

      <div className="container">
        <Appear order={1} hideOnExit={true}>
          <h1 className="heading-l lg:heading-xl mb-6 md:mb-16">{title}</h1>
        </Appear>

        <Appear order={2}>
          <Filters filters={filters} selected={selectedFilter} setSelected={selectFilter} />
        </Appear>
      </div>

      <div className="container mt-6 pb-4 lg:pb-8">
        <BlogItems items={blogItems} category={selectedFilter} />
      </div>

      <LetsTalk />
    </>
  )
}

export const query = graphql`
  query BlogPageQuery($locale: String!) {
    page: datoCmsBlog(locale: { eq: $locale }) {
      title
      seoMetaTags {
        tags
      }
    }
    categories: allDatoCmsBlogCategory(
      filter: { locale: { eq: $locale } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
      }
    }
    posts: allDatoCmsBlogPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          id
          category {
            name
          }
          publicationDate
          slug
          tags
          title
          thumbnail {
            gatsbyImageData(width: 414, imgixParams: { auto: "format" })
          }
        }
      }
      totalCount
    }
    links: allDatoCmsBlogLink(
      filter: { locale: { eq: $locale } }
      sort: { fields: [publicationDate], order: DESC }
    ) {
      edges {
        node {
          id
          category {
            name
          }
          publicationDate
          url
          tags
          title
          thumbnail {
            gatsbyImageData(width: 414, imgixParams: { auto: "format" })
          }
        }
      }
      totalCount
    }
  }
`

export default Insights
